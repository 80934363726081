const APP_INIT = "app/INIT";
const APP_SET_SUCCESS_SNACKBAR = "app/SET_SUCCESS_SNACKBAR";
const APP_SET_INFO_SNACKBAR = "app/SET_INFO_SNACKBAR";
const APP_SET_ERROR_SNACKBAR = "app/SET_ERROR_SNACKBAR";
const APP_SET_WARNING_SNACKBAR = "app/SET_WARNING_SNACKBAR";

const defaultValues = {
  init: false,
  defaultTemplate: null,
  successSnackbar: null,
  infoSnackbar: null,
  errorSnackbar: null,
  warningSnackbar: null,
  nextEsopData: {
    nextEsopId: 0,
    esopPrefix: "",
    separator: "-",
    fullEsopId: "",
  },
};

const app = (state = { ...defaultValues }, { type, payload }) => {
  switch (type) {
    case APP_SET_WARNING_SNACKBAR:
      return {
        ...state,
        warningSnackbar: payload.data,
      };
    case APP_SET_INFO_SNACKBAR:
      return {
        ...state,
        infoSnackbar: payload.data,
      };
    case APP_SET_ERROR_SNACKBAR:
      return {
        ...state,
        errorSnackbar: payload.data,
      };
    case APP_SET_SUCCESS_SNACKBAR:
      return {
        ...state,
        successSnackbar: payload.data,
      };
    case APP_INIT:
      return {
        ...state,
        init: true,
      };

    default:
      return state;
  }
};
export default app;

export const initializeApp = () => (dispatch) => {
  dispatch({ type: APP_INIT });
};
