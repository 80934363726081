import React from "react";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <React.Fragment>
      <div>
        <h3>Page Not Found</h3>
        <div className="mt-4 text-center">
          <Link to="/">Back</Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Page404;
