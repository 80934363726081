import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { resetUser } from "../reducers/users";

export default function Results() {
  let dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  if (user === null) return null;
  return (
    <Modal centered show onHide={() => dispatch(resetUser())}>
      <Modal.Body>
        <h2 className="text-red">You are at RISK!</h2>
        <p>You did not pass the phising security training!</p>
        <p>
          Be careful when entering your email and password. Please make sure
          that you double check the domain, email sender, etc before entering
          sensitive data.
        </p>
        <p>
          Don't worry, we don't store your password. This is just for a test.
        </p>
        <div>Your email: {user.email}</div>
        <div>Your password length: {user.passwordLength}</div>
      </Modal.Body>
    </Modal>
  );
}
