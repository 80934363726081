import React, { useEffect } from "react";
import { loadCSS } from "fg-loadcss";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";

import history from "./history";

import Page404 from "../screens/404";
import {
  COMMON_ROUTE,
} from "../constants/routes";
import ScrollToTopWrapper from "./scroll-to-top-wrapper";
import { initializeApp } from "../reducers/app";

const MainRouter = () => {
  const dispatch = useDispatch();
  const appReady = useSelector((state) => state.app.init);

  useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.15.3/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  useEffect(() => {
    dispatch(initializeApp());
  }, [dispatch]);

  
  if (!appReady) {
    return null;
  }

  return (
    <BrowserRouter>
      <Router history={history}>
        <ScrollToTopWrapper>
          <Switch>
            {Object.values(COMMON_ROUTE).map((r) => {
              return (
                <Route
                  exact
                  path={r.path}
                  component={r.component}
                  key={r.path}
                />
              );
            })}
            <Route exact component={Page404} />
          </Switch>
        </ScrollToTopWrapper>
      </Router>
    </BrowserRouter>
  );
};

export default MainRouter;
