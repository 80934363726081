import GoogleLogin from "../screens/google-login";
import Articles from "../screens/articles";

export const COMMON_ROUTE = {
  home: {
    path: "/",
    component: Articles,
  },
  login: {
    path: "/google-login",
    component: GoogleLogin,
  },
};
