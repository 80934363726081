import React, { Component } from "react";

class InputWithLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputType: props.type || "text",
      isFocus: false,
      value: props.value || "",
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.ref.focus();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.value && nextProps.value !== this.state.value) {
      this.setState({
        value: nextProps.value,
      });
    }
    if (nextProps.type && nextProps.type !== this.state.type) {
      this.setState({
        inputType: nextProps.type,
      });
    }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
    this.props.onChange(e);
  };

  onClickLabel = () => {
    this.ref.focus();
  };

  render() {
    const {
      placeholder,
      onChange,
      required,
      type,
      additionalClass,
      ...rest
    } = this.props;
    const { isFocus, value, inputType } = this.state;
    let activeClass = "";
    if (isFocus || value !== "") {
      activeClass += " active";
    }
    let requiredIcon = "";
    if (typeof required !== "undefined" && required) {
      if (activeClass !== "") {
        requiredIcon = <span style={{ color: "red" }}>*</span>;
      } else {
        requiredIcon = <span>*</span>;
      }
    }
    return (
      <div className={`input-container ${activeClass} ${additionalClass}`}>
        <div
          className={`input-label ${activeClass} ${additionalClass}`}
          onClick={this.onClickLabel}
        >
          {placeholder}
          {requiredIcon}
        </div>
        <input
          ref={(el) => (this.ref = el)}
          type={inputType}
          required={typeof required === "undefined" ? false : required}
          {...rest}
          onChange={this.onChange}
          onFocus={() => this.setState({ isFocus: true })}
          onBlur={() => this.setState({ isFocus: false })}
        />
      </div>
    );
  }
}

export default InputWithLabel;
