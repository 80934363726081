import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import ReduxPromise from "redux-promise";
import ReduxThunk from "redux-thunk";

import reducers from "./reducers";
import MainRouter from "./routers";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";

import config from "./config";

// import * as Sentry from '@sentry/browser'

// if (config.sentryUrl !== '') {
//   Sentry.init({ dsn: config.sentryUrl })
// }

import firebase from "@firebase/app";
import "@firebase/firestore";

firebase.initializeApp(config.firebase);

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(ReduxPromise, ReduxThunk))
);

ReactDOM.render(
  <Provider store={store}>
    <MainRouter />
  </Provider>,
  document.getElementById("root")
);
