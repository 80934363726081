import React from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import Results from "../components/results";
import { WEB_TITLE } from "../constants/config_ui";
import Login, {
  LOGIN_MODAL_TYPE,
} from "../screens/articles/subcomponents/login";
import logo from "../assets/images/logo.png";

export default function TemplatesArticles(props) {
  const { referrer, children } = props;
  return (
    <div className="articles">
      <header className="articles-header">
        <Container className="sub-container">
          <div className="logo-container">
            <span className="logo link">
              <Image src={logo} title={WEB_TITLE} />
            </span>
          </div>
          <div className="nav-container">
            <nav className="">
              <ul className="">
                <li className="">
                  <Login
                    className="link active"
                    referrer={referrer}
                    type={LOGIN_MODAL_TYPE.loginIn}
                  >
                    Premium Contents
                  </Login>
                </li>
                <li className="">
                  <Login
                    className="link"
                    referrer={referrer}
                    description="to see list of companies that we have"
                    type={LOGIN_MODAL_TYPE.loginIn}
                  >
                    Companies
                  </Login>
                </li>
                <li className="">
                  <Login
                    className="link"
                    referrer={referrer}
                    description="to know about our events"
                    type={LOGIN_MODAL_TYPE.loginIn}
                  >
                    Events
                  </Login>
                </li>
                <li className="">
                  <Login
                    className="link"
                    referrer={referrer}
                    description="to contact us"
                    type={LOGIN_MODAL_TYPE.loginIn}
                  >
                    Contact Us
                  </Login>
                </li>
              </ul>
              <div className=" aside-nav">
                <ul className="">
                  <li className="">
                    <Login
                      className="link"
                      referrer={referrer}
                      type={LOGIN_MODAL_TYPE.signUp}
                    >
                      Sign Up
                    </Login>
                  </li>
                  <li className="">
                    <Login className="link login-link" referrer={referrer}>
                      Log In
                    </Login>
                  </li>
                </ul>
                <div className="aside-sub-nav">
                  <span className="link">
                    <svg fill="currentColor" width="24" height="24">
                      <path d="M10.6 17.81a7.22 7.22 0 11.02-14.43 7.22 7.22 0 01-.02 14.43m11.2 3l-4.64-4.64a8.56 8.56 0 002.05-5.57 8.61 8.61 0 10-8.6 8.6 8.56 8.56 0 005.56-2.04l4.64 4.64a.7.7 0 00.99 0 .7.7 0 000-1"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </nav>
          </div>
        </Container>
      </header>
      <Container className="content-container">
        <Row>
          <Col xs={12} md={8}>
            {children}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}>
            <div className="closing">
              <h2>Like our articles?</h2>
              <Login className="link link-login" referrer={referrer}>
                Login
              </Login>{" "}
              to read the other premium contents
            </div>
          </Col>
        </Row>
      </Container>
      <Results />
    </div>
  );
}
