// REDUCER is used to map what is listed in action (dispatch function). parameter in dispatch function will move to 2nd parameter in each reducer
import { combineReducers } from "redux";

import app from "./app";
import users from "./users";

// combineReducers will make the state to be inside this object. i.e: checkUser, auth, etc.
const rootReducer = combineReducers({
  app,
  users,
});

export default rootReducer;
