import merge from "deepmerge";
import config from "./default";
import prod from "./prod";
import dev from "./dev";

const env = process.env.REACT_APP_ENV;
let conf = config;
switch (env) {
  case "production":
    conf = { ...merge(conf, prod) };
    break;
  case "dev":
  default:
    conf = { ...merge(conf, dev) };
    break;
}

export default conf;
