import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { COMMON_ROUTE } from "../../../constants/routes";
import history from "../../../routers/history";

export const LOGIN_MODAL_TYPE = {
  signUp: "Sign Up",
  loginIn: "Log In",
};
export default function Login(props) {
  const {
    referrer,
    description = "to read our premium contents.",
    type = LOGIN_MODAL_TYPE.loginIn,
    children,
    ...rest
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [openType, setOpenType] = useState(type);
  return (
    <React.Fragment>
      <span {...rest} onClick={() => setOpenModal(true)}>
        {children}
      </span>
      {openModal && (
        <Modal
          centered
          show
          onHide={() => setOpenModal(false)}
          className="articles-login-modal"
        >
          <Modal.Body className="p-0">
            <div className="login-modal-type">
              <span
                onClick={() => setOpenType(LOGIN_MODAL_TYPE.signUp)}
                className={
                  openType === LOGIN_MODAL_TYPE.signUp ? "active link" : "link"
                }
              >
                Sign up
              </span>
              <span
                onClick={() => setOpenType(LOGIN_MODAL_TYPE.loginIn)}
                className={
                  openType === LOGIN_MODAL_TYPE.loginIn ? "active link" : "link"
                }
              >
                Log in
              </span>
            </div>
            <div className="login-modal-content">
              <h2>{openType}</h2>
              <p>{description}</p>
              <div>
                <Button
                  type="button"
                  variant="outline-secondary"
                  onClick={() =>
                    history.push(COMMON_ROUTE.login.path + "?ref=" + referrer)
                  }
                >
                  <span>
                    <svg viewBox="0 0 48 48" width="16" height="16">
                      <defs>
                        <path
                          id="a"
                          d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                        ></path>
                      </defs>
                      <clipPath id="b">
                        <path
                          id="a"
                          d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                        ></path>
                      </clipPath>
                      <path
                        clipPath="url(#b)"
                        fill="#FBBC05"
                        d="M0 37V11l17 13z"
                      ></path>
                      <path
                        clipPath="url(#b)"
                        fill="#EA4335"
                        d="M0 11l17 13 7-6.1L48 14V0H0z"
                      ></path>
                      <path
                        clipPath="url(#b)"
                        fill="#34A853"
                        d="M0 37l30-23 7.9 1L48 0v48H0z"
                      ></path>
                      <path
                        clipPath="url(#b)"
                        fill="#4285F4"
                        d="M48 48L17 24l-4-3 35-10z"
                      ></path>
                    </svg>
                    {openType} with Google
                  </span>
                </Button>
              </div>
            </div>
          </Modal.Body>
          <div className="modal-close" onClick={() => setOpenModal(false)}>
            <svg fill="currentColor" width={24} height={24}>
              <path d="M12.97 12l6.83-6.83a.69.69 0 10-.97-.97L12 11.03 5.17 4.2a.69.69 0 00-.97.97L11.03 12 4.2 18.83a.69.69 0 10.97.97L12 12.97l6.83 6.83a.68.68 0 00.97 0 .69.69 0 000-.97L12.97 12z"></path>
            </svg>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
}
