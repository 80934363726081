const config = {
  site: {
    domain: "insignia.vc",
    URL: "https://svested.insignia.vc", // dont give "/" at the end
  },

  gaTrackingId: "",

  firebase: {
    apiKey: "AIzaSyCHlBRVyUaflbyGPeCZ97RjeejfOSwdseQ",
    authDomain: "phising-test.firebaseapp.com",
    projectId: "phising-test",
    storageBucket: "phising-test.appspot.com",
    messagingSenderId: "185463151704",
    appId: "1:185463151704:web:b1b77504b034a8a450be2d",
    measurementId: "G-RP7ZXSP0KR",
  },
  userCollection: 'users-2024'
};

export default config;
