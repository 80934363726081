import React, { useEffect, Fragment, useRef } from "react";
import { withRouter } from "react-router-dom";

function ScrollToTopWrapper({ history, children }) {
  const latestHistory = useRef(history);
  useEffect(() => {
    latestHistory.current = history;
  });
  useEffect(() => {
    const unlisten = latestHistory.current.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTopWrapper);
