import moment from "moment";

export function compareSort(a, b, sortType) {
  if (a === null) a = 0;
  if (b === null) b = 0;
  if (a < b) {
    if (sortType === "desc") {
      return 1;
    }
    return -1;
  }
  if (a > b) {
    if (sortType === "desc") {
      return -1;
    }
    return 1;
  }
  return 0;
}

export function displayDateFromUnix(
  unixtime,
  displayDate = true,
  displayTime = true,
  displaySec = false
) {
  if (unixtime === 0) return "";
  var dateConverted = new Date(unixtime * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = dateConverted.getFullYear();
  var month = months[dateConverted.getMonth()];
  var date = dateConverted.getDate();
  var hour = dateConverted.getHours();
  if (hour < 10) hour = "0" + hour;
  var min = dateConverted.getMinutes();
  if (min < 10) min = "0" + min;
  var sec = dateConverted.getSeconds();
  if (sec < 10) sec = "0" + sec;
  var time = "";
  if (displayDate) time = time + date + " " + month + " " + year;
  if (displayTime) {
    if (time !== "") time = time + " ";
    time = time + hour + ":" + min;
    if (displaySec) time = time + ":" + sec;
  }
  return time;
}

export function formatDate(date) {
  if (!date) {
    return date;
  }

  date = new Date(date);
  var day = date.getDate();
  day = day < 10 ? "0" + day : day;
  var month = date.getMonth();
  month = month < 9 ? "0" + (month + 1) : month + 1;
  return date.getFullYear() + "-" + month + "-" + day;
}

export function formatDateTime(date, displayDate = true, displayTime = true) {
  if (!date) {
    return date;
  }
  let convertdate = new Date(date);
  let returnDate = "";
  if (displayDate) {
    returnDate += formatDate(date);
  }
  if (displayTime) {
    if (returnDate !== "") {
      returnDate += " ";
    }
    let hours = convertdate.getHours();
    if (hours < 10) {
      hours = "0" + hours;
    }
    let minutes = convertdate.getMinutes();
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    returnDate += hours + ":" + minutes;
  }
  return returnDate;
}

export function displayDaysAgo(date) {
  let days = Math.floor((new Date() - new Date(date)) / (1000 * 60 * 60 * 24));
  let dayText = "";
  if (days === 0) {
    dayText = "Today";
  } else if (days === 1) {
    dayText = "Yesterday";
  } else {
    dayText = days + " days ago";
  }

  if (days > 180) {
    dayText = "at " + formatDate(date);
  }
  return dayText;
}

export function isValidURL(string) {
  var res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  if (res == null) {
    return false;
  } else {
    return true;
  }
}

export function generateRandomString(stringLength = 8) {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < stringLength; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

export function capitalizeFirstLetter(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

var Formatter = {
  currency(value, currency = null) {
    if (currency === null) currency = "$";
    if (value === null) return null;
    if (currency !== "" && !currency.endsWith(" ")) {
      currency = currency + " ";
    }
    if (typeof value !== "undefined" && Number(value) === 0) {
      return currency + value;
    }
    const normalisedValue = this.normalised(value);
    return currency + normalisedValue;
  },

  fullCurrency(
    value,
    currency = null,
    totalDecimal = 2,
    fillEmptyDecimal = false
  ) {
    if (currency === null) currency = "$";
    if (value === null) return null;
    if (currency !== "" && !currency.endsWith(" ")) {
      currency = currency + " ";
    }
    if (typeof value !== "undefined" && Number(value) === 0) {
      return currency + value;
    }
    const decRound = Math.pow(10, totalDecimal);
    value = Math.round(value * decRound) / decRound;
    let normalisedValue = this.numberToCommaString(value);
    if (normalisedValue.toString().indexOf(".") > -1) {
      let numSplit = normalisedValue.split(".");
      if (numSplit.length === 2) {
        if (numSplit[1].length < totalDecimal && fillEmptyDecimal) {
          for (let i = numSplit[1].length; i < totalDecimal; i++) {
            numSplit[1] = numSplit[1] + "0";
          }
        }
        if (Number(numSplit[1]) === 0) {
          normalisedValue = numSplit[0];
        } else {
          normalisedValue = numSplit.join(".");
        }
      }
    } else if (fillEmptyDecimal && totalDecimal > 0) {
      normalisedValue += ".";
      for (let i = 0; i < totalDecimal; i++) {
        normalisedValue = normalisedValue + "0";
      }
    }
    return currency + " " + normalisedValue;
  },

  normalised(value) {
    if (!value) return value;

    if (value.toString().indexOf(".") > -1) {
      value = Math.round(value);
    }
    const numDigits = value.toString().length;
    const suffixIndex = Math.floor((numDigits - 1) / 3);

    let normalisedValue = value / Math.pow(1000, suffixIndex);
    normalisedValue = Math.round(normalisedValue * 100) / 100;
    const suffixes = ["", "K", "M", "B", "T"];
    return normalisedValue + suffixes[suffixIndex];
  },

  numberToCommaString(number) {
    if (!number) return number;
    number = number.toString();
    if (number.indexOf(".") > -1) {
      let numSplit = number.split(".");
      let ret = number;
      if (numSplit.length > 0) {
        ret = numSplit[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        ret += ".";
        numSplit.splice(0, 1);
        ret += numSplit.join("");
      }
      return ret;
    }
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  numberToFrequency(number) {
    if (!Number.isInteger(number)) return number;
    let result = number;
    switch (number) {
      case 1:
        result = "once";
        break;
      case 2:
        result = "twice";
        break;
      default:
        result = number + " times";
        break;
    }
    return result;
  },
};

export default Formatter;

export function serializeQueryString(obj) {
  if (!obj) return "";
  return Object.keys(obj)
    .reduce(function(a, k) {
      if (Array.isArray(obj[k])) {
        for (var val of obj[k]) {
          a.push(k + "[]=" + encodeURIComponent(val));
        }
      } else if (obj[k] !== null && obj[k] !== undefined) {
        a.push(k + "=" + encodeURIComponent(obj[k]));
      }
      return a;
    }, [])
    .join("&");
}

export function validateEmail(email) {
  // eslint-disable-next-line
  const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return expression.test(String(email).toLowerCase());
}

export function stripHtml(html) {
  // Create a new div element
  var temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

export function compareDate(compare, start, end) {
  const compareDate = moment(compare);
  const startDate = moment(start);
  const endDate = moment(end);
  return compareDate.isBetween(startDate, endDate, "days", "[]");
}

export function getUniqueArray(arr) {
  return arr.reduce((acc, current) => {
    const findIndex = acc.findIndex((item) => item.id === current.id);
    if (findIndex === -1) {
      return acc.concat([current]);
    } else {
      if (typeof current === "object") {
        acc[findIndex] = {
          ...acc[findIndex],
          ...current,
        };
      }
      return acc;
    }
  }, []);
}

export function compareObject(obj1, obj2) {
  // Loop through properties in object 1
  for (let p in obj1) {
    // Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

    switch (typeof obj1[p]) {
      // Deep compare objects
      case "object":
        if (!compareObject(obj1[p], obj2[p])) return false;
        break;
      // Compare function code
      case "function":
        if (
          typeof obj2[p] === "undefined" ||
          (p !== "compare" && obj1[p].toString() !== obj2[p].toString())
        )
          return false;
        break;
      // Compare values
      default:
        if (obj1[p] !== obj2[p]) return false;
    }
  }

  // Check object 2 for any extra properties
  for (let p in obj2) {
    if (typeof obj1[p] === "undefined") return false;
  }
  return true;
}

export function getFormData(formData, data, previousKey = null) {
  if (data instanceof Object) {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (
        value instanceof Object &&
        !Array.isArray(value) &&
        !(value instanceof File)
      ) {
        return getFormData(formData, value, key);
      }
      if (previousKey) {
        key = `${previousKey}[${key}]`;
      }
      if (Array.isArray(value)) {
        value.forEach((val, idx) => {
          if (
            val instanceof Object &&
            !Array.isArray(val) &&
            !(val instanceof File)
          ) {
            return getFormData(formData, val, `${key}[${idx}]`);
          } else {
            formData.append(`${key}[]`, val);
          }
        });
      } else {
        if (value !== null) {
          formData.append(key, value);
        }
      }
    });
  }
  return formData;
}

export function checkErrors(values, isDirty, additionalCheck = null) {
  const errors = {};
  Object.keys(values).forEach((key) => {
    if (typeof isDirty[key] !== "undefined") {
      errors[key] =
        isDirty[key] && values[key] !== null && values[key].length === 0;
      if (additionalCheck && additionalCheck[key] && isDirty[key]) {
        errors[key] = errors[key] || additionalCheck[key]();
      }
    }
  });
  return errors;
}

export function checkBeforeSubmit(isDirty, errors) {
  let allDirty = true;
  Object.values(isDirty).forEach((d) => {
    allDirty = allDirty && d;
  });
  let haveError = false;
  Object.values(errors).forEach((d) => {
    haveError = haveError || d;
  });
  return {
    allDirty,
    haveError,
  };
}
